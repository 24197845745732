import React, { useState, useEffect, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery } from "@apollo/react-hooks";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { KIT_ASSETS_QUERY } from "src/constants/graphql/queries";
import ItemId from "src/views/shopping/Orders/ItemId";
import ScanOutModal from "../ScanOutModal";
import useAuth from "src/hooks/useAuth";
import useMutation from "src/hooks/useMutation";
import { UPDATE_INVENTORY_ITEM_MUTATION } from "src/constants/graphql/mutations";
import { getInventoryFields } from "src/views/inventory/requests/CartItemFields";

export default function ManualLocationKitAssets({ assetId, rackLocations, item }) {
  const { user } = useAuth();
  const { settings } = user.company;
  const { loading, data: { asset } = {} } = useQuery(KIT_ASSETS_QUERY, {
    variables: { id: assetId },
    fetchPolicy: "network-only",
  });
  const [openScanModal, setOpenScanModal] = useState(false);
  const [pickedItems, setPickedItems] = useState({});
  const [pickedStatus, setPickedStatus] = useState(false);
  const [updateItem, { loading: mutationLoading }] = useMutation(UPDATE_INVENTORY_ITEM_MUTATION);
  const mutationCalled = useRef(false);

  const getRackLocation = (kitAssetId) => {
    const locations = rackLocations
      .filter((rl) => String(rl.asset_id) === String(kitAssetId))
      .map((location) => `${location.rack_location.join(", ")} (${location.sub_qty})`);
    return locations.length > 0 ? locations.join("\n") : "No specific location";
  };

  const getTotalQuantity = (kitAssetId) => {
    return rackLocations
      .filter((rl) => String(rl.asset_id) === String(kitAssetId))
      .reduce((total, location) => total + location.sub_qty, 0);
  };

  const sortedAssets = (asset?.kitAssets || [])
    .map((v) => ({ ...v.asset, quantity: getTotalQuantity(v.asset.id) }))
    .sort((a, b) => getRackLocation(a.id).localeCompare(getRackLocation(b.id)));

  useEffect(() => {
    if (
      sortedAssets.length &&
      Object.keys(pickedItems).length === sortedAssets.length &&
      !pickedStatus &&
      !mutationLoading &&
      !mutationCalled.current
    ) {
      const allPicked = sortedAssets.every(({ id }) => pickedItems[id]);
      if (allPicked) {
        mutationCalled.current = true;
        const variables = {
          id: item.id,
          ...getInventoryFields({
            ...item,
            pickedStatus: true,
          }),
        };
        updateItem({ variables }).then(() => setPickedStatus(true));
      }
    }
  }, [pickedItems, sortedAssets, item, updateItem, pickedStatus, mutationLoading]);

  const handleMarkAsPicked = (kitAssetId) => {
    setPickedItems((prev) => ({ ...prev, [kitAssetId]: true }));
  };

  const handleScanComplete = (kitAssetId) => {
    setPickedItems((prev) => ({ ...prev, [kitAssetId]: true }));
    setOpenScanModal(false);
  };

  return loading ? (
    <CircularProgress sx={{ ml: 1 }} size={22} />
  ) : (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="center">Image</TableCell>
          <TableCell>Item ID</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Quantity Requested</TableCell>
          <TableCell>Pick Location(s)</TableCell>
          <TableCell align="right" width={160}>Pick Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedAssets.map((asset, index) => (
          <TableRow key={index}>
            <TableCell align="center">
              {asset.images && asset.images[0]?.url ? (
                <img src={asset.images[0].url} alt={asset.description} style={{ maxWidth: 90, maxHeight: 60 }} />
              ) : (
                "No image"
              )}
            </TableCell>
            <TableCell>
              <ItemId asset={asset} />
            </TableCell>
            <TableCell>{asset.description}</TableCell>
            <TableCell>{asset.quantity}</TableCell>
            <TableCell>
              {getRackLocation(asset.id).split("\n").map((line, i) => (
                <React.Fragment key={i}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </TableCell>
            <TableCell align="right">
              {pickedItems[asset.id] ? (
                <Button
                  sx={{
                    color: "green",
                    textTransform: "capitalize",
                    "&.Mui-disabled": { color: "green" },
                  }}
                  disabled
                >
                  Picked
                </Button>
              ) : settings.scan_out_modal ? (
                <Button
                  startIcon={loading && <CircularProgress size={22} />}
                  onClick={() => setOpenScanModal(true)}
                  sx={{
                    color: "red",
                    textTransform: "capitalize",
                    "&.Mui-disabled": { color: "green" },
                  }}
                >
                  Scan Out
                </Button>
              ) : (
                <Button
                  startIcon={loading && <CircularProgress size={22} />}
                  onClick={() => handleMarkAsPicked(asset.id)}
                  sx={{
                    color: "red",
                    textTransform: "capitalize",
                    "&.Mui-disabled": { color: "green" },
                  }}
                >
                  Mark as Picked
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      {openScanModal && (
        <ScanOutModal
          itemId={assetId}
          onClose={() => setOpenScanModal(false)}
          onScanComplete={() => handleScanComplete(assetId)}
        />
      )}
    </Table>
  );
}
