import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";

import { useQuery } from "@apollo/react-hooks";
import { replaceCollection } from "../../../components/TablePro/utils";
import ActionModal from "../../../components/modals/action-modal";
import useMutation from "../../../hooks/useMutation";
import { CREATE_INVENTORY_TRANSACTION_MUTATION } from "../../../constants/graphql";
import { ASSETS_COLLECTION_RESTOCK_INVENTORY_QUERY } from "../../../constants/graphql/queries";
import { ASSET_REPLAY_LOGS_QUERY, ASSET_AUDIT_LOGS_QUERY } from "../../../constants/graphql/queries";
import { useAssetsCollection } from "../../../contexts/assetsCollection";
import RackLocationsForm from "./RackLocationsForm";
import useAuth from "src/hooks/useAuth";
import useNotification from "../../../hooks/notification";

export default function RackLocationsModal({ open, toggleModal, entity, addAction }) {
  const { setCollection, setLoadingMutation } = useAssetsCollection();
  const { user } = useAuth();
  const { notifySuccess, notifyError } = useNotification();
  const [createTransactionMutation] = useMutation(CREATE_INVENTORY_TRANSACTION_MUTATION);
  const [assetData, setAssetData] = useState(null);
  const { loading, data, refetch } = useQuery(ASSETS_COLLECTION_RESTOCK_INVENTORY_QUERY, {
    variables: { search: entity.assetId, type: "Asset" },
    skip: !entity.assetId,
  });

  useEffect(() => {
    if (data && data.assetsCollection.collection.length) {
      const fetchedAsset = data.assetsCollection.collection[0];
      setAssetData(fetchedAsset);
    }
  }, [data]);

  const handleSave = values => {
    setLoadingMutation(true);

    const locations = values.rackLocations.map(location => ({
      sub_qty: location.sub_qty,
      rack_location: location.rack_location,
      warehouse: location.warehouse,
      allocated_quantity: location.allocated_quantity ?? 0,
    }));

    const oldRackLocations = assetData?.rackLocations.map(location => ({
      rack_location: location.rack_location,
      warehouse: location.warehouse,
      sub_qty: location.sub_qty,
    })) || [];

    const transactionPayload = {
      code: entity.assetId || "",
      notes: values.notes || "",
      senderName: values.senderName || "",
      carrierName: values.carrierName || "",
      trackingNumber: values.trackingNumber || "",
      orderNumber: values.orderNumber || "",
      poNumber: values.poNumber || "",
      quantity: values.quantity || 0,
      rackLocations: locations,
      rackLocationsChanges: [],
      oldRackLocations,
      id: entity.id,
    };

    const refetchQueries = [
      user.company.settings.asset_replay_logs
        ? {
            query: ASSET_REPLAY_LOGS_QUERY,
            variables: { assetId: entity.id },
          }
        : {
            query: ASSET_AUDIT_LOGS_QUERY,
            variables: { assetId: entity.id, action: "replenish_inventory" },
          },
    ];

    createTransactionMutation({
      variables: transactionPayload,
      refetchQueries,
      onSuccess: () => {
        setLoadingMutation(false);
        notifySuccess("Asset successfully updated!");
        toggleModal();
        if (addAction) {
          addAction([
            {
              [entity.id]: {
                rackLocations: entity.rackLocations,
              },
            },
            { [entity.id]: values },
          ]);
        }
      },
      onFailure: error => {
        setLoadingMutation(false);
        notifyError(errors.submit);
      },
    });
  };

  return open ? (
    <ActionModal
      open
      title="Storage Location(s)"
      maxWidth={640}
      onClose={toggleModal}
      disableTransition
      disableBackdropClose={true}
    >
      <Box mt={1} />
      <RackLocationsForm
        asset={entity}
        rackLocations={entity.rackLocations || []}
        toggleModal={toggleModal}
        onSubmit={handleSave}
      />
    </ActionModal>
  ) : null;
}
