import React, {useState, useEffect} from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {getImage} from "src/views/shopping/Cart/CartItemFields";
import ItemId from "src/views/shopping/Orders/ItemId";
import Dimensions from "src/views/inventory/requests/Dimensions";
import useMutation from "src/hooks/useMutation";
import {UPDATE_INVENTORY_ITEM_MUTATION} from "src/constants/graphql/mutations";
import {getInventoryFields} from "src/views/inventory/requests/CartItemFields";
import ScanOutModal from "./ScanOutModal";
import useAuth from "src/hooks/useAuth";
import ScanditScanner, {configureScandit} from "src/components/barcode/ScanditScanner/ScanditScanner";

export default function ManualLocationItem({ item, asset, rackLocations, onPickKit }) {
  const { user } = useAuth();
  const { settings } = user.company;
  const [updateItem, { loading }] = useMutation(UPDATE_INVENTORY_ITEM_MUTATION);
  const [openScanModal, setOpenScanModal] = useState(false);

  useEffect(() => {
    if (settings.scan_out_modal) {
      try {
        configureScandit(user.settings.scandid_license);
      } catch (error) {
        console.error("Failed to configure Scandit scanner:", error);
      }
    }
  }, [settings.scan_out_modal, user.settings.scandid_license]);

  const handleScanComplete = (barcodeMatch) => {
    if (barcodeMatch) {
      // If scan is successful, mark item as picked
      const variables = {
        id: item.id,
        ...getInventoryFields({
          ...item,
          pickedStatus: true,
        }),
      };
      return updateItem({ variables });
    }
  };

  const isKit = asset.itemId.startsWith("KIT");

  const handleOpenKitModal = () => {
    onPickKit(asset.id, rackLocations);
  };

  const handleMarkAsPicked = () => {
    const variables = {
      id: item.id,
      ...getInventoryFields({
        ...item,
        pickedStatus: true,
      }),
    };
    return updateItem({ variables });
  };

  const photo = getImage(asset);
  const highlight = item.quantity > asset?.availableQuantity;
  const background = highlight ? "#fffbf2" : undefined;

  return (
    <>
      <TableRow style={{ width: 90 }}>
        <TableCell align="center" style={{ background }}>
          {photo ? <img style={{ maxWidth: 90, maxHeight: 60 }} src={photo} alt="" /> : "No image"}
        </TableCell>
        <TableCell>
          <ItemId asset={asset} />
        </TableCell>
        <TableCell>{asset.description}</TableCell>
        <TableCell>{item.quantity}</TableCell>
        <TableCell>
          {isKit && !item.pickedStatus ? (
            "Various Locations"
          ) : (
            rackLocations.map((v, index) => (
              <Box key={index}>
                {v.rack_location.join(", ")} ({v.sub_qty})
              </Box>
            ))
          )}
        </TableCell>
        <TableCell align="right">
          {isKit && !item.pickedStatus ? (
            <Button onClick={handleOpenKitModal}>Pick Kit</Button>
          ) : settings.scan_out_modal ? (
            <Button
              startIcon={loading && <CircularProgress size={22} />}
              onClick={() => setOpenScanModal(true)}
              disabled={loading || item.pickedStatus}
              sx={{
                color: item.pickedStatus ? "green" : "red",
                textTransform: "capitalize",
                "&.Mui-disabled": { color: item.pickedStatus ? "green" : "red" },
              }}
            >
              {item.pickedStatus ? "Picked" : "Scan Out"}
            </Button>
          ) : (
            <Button
              startIcon={loading && <CircularProgress size={22} />}
              onClick={handleMarkAsPicked}
              disabled={loading || item.pickedStatus}
              sx={{
                color: item.pickedStatus ? "green" : "red",
                textTransform: "capitalize",
                "&.Mui-disabled": { color: item.pickedStatus ? "green" : "red" },
              }}
            >
              {item.pickedStatus ? "Picked" : "Mark as Picked"}
            </Button>
          )}
        </TableCell>
      </TableRow>

      {openScanModal && (
        <ScanOutModal
          itemId={asset.itemId}
          onClose={() => setOpenScanModal(false)}
          onScanComplete={handleScanComplete}
        />
      )}
    </>
  );
}
