import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import ManualLocationKitAssets from "./ManualLocationKitAssets";

export default function ManualLocationKit({ assetId, rackLocations, onClose, item }) {
  console.log("ManualLocationKit - Received Asset ID:", assetId);
  console.log("ManualLocationKit - Received Rack Locations:", rackLocations);

  return (
    <Dialog open fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box width={40} />
          <Typography fontWeight={500} fontSize={22} color="textPrimary">
            Pick Kit Items
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ minHeight: 200 }}>
        <ManualLocationKitAssets assetId={assetId} rackLocations={rackLocations} item={item} />
      </DialogContent>
    </Dialog>
  );
}
